<template>
  <div class="side-master">
    <div class="side-tit">
      <div class="side-tit-left">{{ $t('bbs.circle_owner') }}</div>
      <!-- 圈主 -->
    </div>
    <div class="side-master-box">
      <div class="user">
        <div class="avatar">
          <a-avatar
            :size="40"
            :src="
              data.masterPortrait || require(`@/assets/image/bbs/avatar.png`)
            "
          >
            <OpenData type="userName" :openid="data.masterName" />
          </a-avatar>
        </div>
        <div class="text">
          <div class="name">
            <OpenData type="userName" :openid="data.masterName" />
          </div>
          <div class="department">
            <OpenData type="departmentName" :openid="data.masterDepartment" />
          </div>
        </div>
      </div>
      <div class="content">
        <p>{{ data.masterIntro }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import OpenData from "@/components/OpenData.vue";
export default {
  name: "sideMaster",
  components: {
    OpenData,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="less" scoped>
.side-tit {
  padding: 15px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  line-height: 24px;
  .mixinFlex(space-between);
  &-left {
    font-size: 16px;
    color: #202020;
  }
  &-right {
    a {
      font-size: 14px;
      color: #666666;
      transition: color 0.3s;
      &:hover {
        color: @color-theme;
      }
    }
  }
}
.side-master {
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  &-box {
    padding: 15px 20px;
    .user {
      margin-bottom: 10px;
      .mixinFlex(space-between;center);
      .avatar {
        width: 40px;
        height: 40px;
      }
      .text {
        width: calc(100% - 48px);
        .name {
          font-size: 14px;
          color: #333;
          .mixinEllipsis(22px);
        }
        .department {
          font-size: 12px;
          color: #666;
          .mixinEllipsis(20px);
        }
      }
    }
    .content {
      p {
        font-size: 14px;
        line-height: 22px;
        color: #666;
        margin: 0;
      }
    }
  }
}
</style>
